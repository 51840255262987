import axios from 'axios';
import AuthService from '../services/auth/AuthService';
import { getAuthFromLocalStorage, removeAuthFromLocalStorage } from './storage';

const auth = getAuthFromLocalStorage();
const axiosServices = axios.create({
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    Authorization: 'Bearer ' + auth.dhToken //the token is a variable which holds the token
  }
});

axiosServices.interceptors.response.use(
  async response => response,
  async error => {
    if (error.response?.status === 401) {
      try {
        const resp = await AuthService.refreshToken();
        const token: string = resp?.data?.access_token || '';
        const refreshToken: string = resp?.data?.refreshToken || '';
        localStorage.setItem('dhTenant', token);
        localStorage.setItem('dhRefreshToken', refreshToken);
      } catch (e) {
        removeAuthFromLocalStorage();
        setTimeout(() => {
          window.location.href =
            process.env.REACT_APP_MAIN_DOMAIN || '' + '/auth/sign-in';
        }, 500);
      }
    }
    return Promise.reject(error.response || 'Something went wrong');
  }
);

export default axiosServices;
