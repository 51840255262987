import { createSlice } from '@reduxjs/toolkit';
import { AuthTenantType, AuthUserType } from '../../../types/auth';
import {
  addAuthToLocalStorage,
  getAuthFromLocalStorage,
  removeAuthFromLocalStorage,
  updateUserInLocalStorage
} from '../../../utils/storage';

interface AuthState {
  isAuthenticated: boolean;
  user: AuthUserType | null;
  tenant: AuthTenantType | null;
  token: string | null;
  scopes?: [];
}

const auth = getAuthFromLocalStorage();
console.log(auth);
const user = auth.dhUser;
const token = auth.dhToken;
const tenant = auth.dhTenant;
const scopes = auth.dhScopes;

const initialState: AuthState = {
  isAuthenticated: !!token,
  user,
  tenant,
  token,
  scopes
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLogin(state, action) {
      if (
        action.payload.user &&
        action.payload.tenant &&
        action.payload.token
      ) {
        state.isAuthenticated = true;
        state.user = action.payload.user;
        state.tenant = action.payload.tenant;
        state.token = action.payload.token;
        state.scopes = action.payload.scopes;
        addAuthToLocalStorage(action.payload);
      }
    },

    setDetails(state, action) {
      if (action.payload.user) {
        state.user = action.payload.user;
        updateUserInLocalStorage(action.payload.user, 'dhUser');
      }
      if (action.payload.tenant) {
        state.tenant = action.payload.tenant;
        updateUserInLocalStorage(action.payload.tenant, 'dhTenant');
      }
    },

    setLogout(state) {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.tenant = null;
      removeAuthFromLocalStorage();
    }
  }
});

export const { setLogin, setLogout, setDetails } = authSlice.actions;
export default authSlice.reducer;
