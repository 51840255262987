import { createSlice } from '@reduxjs/toolkit';
import { OHEType } from 'types/ohe';

interface OHEState {
  ohe: OHEType[];
  oheDetail: OHEType;
}

const initialState: OHEState = {
  ohe: [],
  oheDetail: {} as OHEType
};

const oheSlice = createSlice({
  name: 'ohe',
  initialState,
  reducers: {
    setAllOHE(state, action) {
      state.ohe = action.payload.results || [];
    },
    setOneOHE(state, action) {
      state.oheDetail = action.payload.row;
    },
    addOneOHE(state, action) {
      state.ohe = [...state.ohe, action.payload.row];
    },
    updateOneOHE(state, action) {
      state.ohe = state.ohe.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneOHE(state, action) {
      state.ohe = state.ohe.filter(item => item.id !== action.payload.id);
    }
  }
});

export const { setAllOHE, setOneOHE, addOneOHE, updateOneOHE, removeOneOHE } =
  oheSlice.actions;
export default oheSlice.reducer;
