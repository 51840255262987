import { getAuthFromLocalStorage } from '../utils/storage';

export const checkScope = (scope: string) => {
  let status = false;
  try {
    const auth = getAuthFromLocalStorage();
    status = auth.dhScopes.includes(scope);
    // console.log(auth.dhScopes)
    return status;
  } catch (e) {
    return status;
  }
};
