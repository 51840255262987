import { createSlice } from '@reduxjs/toolkit';
import { CSIType } from 'types/csi';

interface CSIState {
  csi: CSIType[];
  csiDetail: CSIType;
}

const initialState: CSIState = {
  csi: [],
  csiDetail: {} as CSIType
};

const csiSlice = createSlice({
  name: 'csi',
  initialState,
  reducers: {
    setAllCSI(state, action) {
      state.csi = action.payload.results || [];
    },
    setOneCSI(state, action) {
      state.csiDetail = action.payload.row;
    },
    addOneCSI(state, action) {
      state.csi = [...state.csi, action.payload.row];
    },
    updateOneCSI(state, action) {
      state.csi = state.csi.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneCSI(state, action) {
      state.csi = state.csi.filter(item => item.id !== action.payload.id);
    }
  }
});

export const { setAllCSI, setOneCSI, addOneCSI, updateOneCSI, removeOneCSI } =
  csiSlice.actions;
export default csiSlice.reducer;
