import axios from 'axios';
import {
  ChangePasswordType,
  ForgotPassword,
  Login,
  ResetPassword
} from '../../types/auth';
import { getAuthFromLocalStorage } from '../../utils/storage';
import api from '../../utils/api';

const authEndpoint: string = `${process.env.REACT_APP_ENDPOINT}/api/auth`;
const clientId = process.env.REACT_APP_CLIENT_ID || null;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET || null;

const auth = getAuthFromLocalStorage();
const login = (data: Login) => {
  return axios.post(`${authEndpoint}/login`, {
    username: data.email,
    password: data.password,
    client_id: clientId,
    client_secret: clientSecret,
    web: true
  });
};
const forgotPassword = (data: ForgotPassword) => {
  return axios.post(`${authEndpoint}/request-password-reset`, data);
};
const resetPassword = (data: ResetPassword) => {
  return axios.post(`${authEndpoint}/password-reset`, data);
};
const changePassword = (data: ChangePasswordType) => {
  return api.post(`${authEndpoint}/tenants/myProfile/changePassword`, data);
};
const refreshToken = () => {
  return axios.post(`${authEndpoint}/refresh`, {
    refresh_token: auth.dhRefreshToken,
    client_id: auth.dhClientId,
    client_secret: auth.dhClientSecret,
    scopes: auth.dhScopes
  });
};
const logout = () => {
  return axios.post(
    `${authEndpoint}/logout`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + auth.dhToken //the token is a variable which holds the token
      }
    }
  );
};
const tokenValidation = (token: string) => {
  return axios.post(
    `${authEndpoint}/check-login`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + token //the token is a variable which holds the token
      }
    }
  );
};

const AuthService = {
  login,
  logout,
  forgotPassword,
  resetPassword,
  tokenValidation,
  refreshToken,
  changePassword
};
export default AuthService;
