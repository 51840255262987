import { createSlice } from '@reduxjs/toolkit';
import { BookingType } from 'types/bookingManagement';

interface BookingState {
  triageCandidates: BookingType[];
  bookings: BookingType[];
}

const initialState: BookingState = {
  triageCandidates: [],
  bookings: []
};

const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    setAllTriageCandidates(state, action) {
      state.triageCandidates = action.payload.results || [];
    },
    setAllBooking(state, action) {
      state.bookings = action.payload.results || [];
    }
  }
});

export const { setAllTriageCandidates, setAllBooking } = bookingSlice.actions;
export default bookingSlice.reducer;
