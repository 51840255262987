import { createSlice } from '@reduxjs/toolkit';
import { VenueType } from '../../../types/actorManagement';

interface VenueTypeState {
  venueTypes: VenueType[];
  venueType: VenueType;
}

const initialState: VenueTypeState = {
  venueTypes: [],
  venueType: {} as VenueType
};

const venueTypeSlice = createSlice({
  name: 'venueType',
  initialState,
  reducers: {
    setAllVenueType(state, action) {
      state.venueTypes = action.payload.results || [];
    },
    addOneVenueType(state, action) {
      state.venueTypes = [...state.venueTypes, action.payload.row];
    },
    setOneVenueType(state, action) {
      console.log('action', action.payload);
      state.venueTypes = state.venueTypes.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
      state.venueType = action.payload.row;
    },
    removeOneVenueType(state, action) {
      state.venueTypes = state.venueTypes.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  setAllVenueType,
  addOneVenueType,
  setOneVenueType,
  removeOneVenueType
} = venueTypeSlice.actions;
export default venueTypeSlice.reducer;
