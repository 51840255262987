import classNames from 'classnames';
import { useAppContext } from 'providers/AppProvider';
import { Navbar } from 'react-bootstrap';
import navBarLogo from 'assets/img/icons/logo-1.png';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarToggleButton from './NavbarToggleButton';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAuthHook from '../../../hooks/useAuthHook';

const NavbarBrand = () => {
  const {
    config: { navbarTopShape, navbarPosition }
  } = useAppContext();
  const { breakpoints } = useBreakpoints();
  const [logo, setLogo] = useState(navBarLogo);
  const { tenant } = useAuthHook();

  useEffect(() => {
    const assetEndPoint = process.env.REACT_APP_ASSET_ENDPOINT;
    if (tenant?.logo && assetEndPoint) {
      setLogo(assetEndPoint + '/' + tenant.logo);
    } else {
      setLogo(navBarLogo);
    }
  }, [tenant]);

  return (
    <>
      <div className="navbar-logo">
        {breakpoints.down('lg') && <NavbarToggleButton />}
        <Navbar.Brand
          as={Link}
          to="/"
          className={classNames({
            'me-1 me-sm-3':
              navbarTopShape === 'slim' || navbarPosition === 'horizontal'
          })}
        >
          {navbarTopShape === 'slim' ? (
            <>
              phoenix{' '}
              <span className="text-body-highlight d-none d-sm-inline">
                slim
              </span>
            </>
          ) : (
            <div className="d-flex align-items-center">
              <img
                src={logo}
                className="company-logo"
                alt="phoenix"
                style={{ maxHeight: '58px' }}
              />
              {/*<p className="logo-text ms-2 d-none d-sm-block">phoenix</p>*/}
            </div>
          )}
        </Navbar.Brand>
      </div>
    </>
  );
};

export default NavbarBrand;
