import SettingsPanel from 'components/settings-panel/SettingsPanel';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import useToggleStyle from 'hooks/useToggleStyle';
import { useAppContext } from 'providers/AppProvider';
import { useSettingsPanelContext } from 'providers/SettingsPanelProvider';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import useAuthHook from './hooks/useAuthHook';

const App = () => {
  const { checkValidation } = useAuthHook();
  // const navigate = useNavigate();
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();
  const [queryParameters] = useSearchParams();
  const [token, setToken] = useState(queryParameters.get('login_token') || '');

  const {
    settingsPanelConfig: { showSettingPanelButton },
    setSettingsPanelConfig
  } = useSettingsPanelContext();

  const {
    config: { theme, isRTL }
  } = useAppContext();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setSettingsPanelConfig({
      openSettingPanel: false
    });
  }, [isRTL]);
  useEffect(() => {
    const appEnv = process.env.REACT_APP_APP_ENV || 'local';
    if (appEnv.toLowerCase() != 'local') {
      const refreshToken = queryParameters.get('refresh_token') || '';
      const clientId = queryParameters.get('client_id') || '';
      const clientSecret = queryParameters.get('client_secret') || '';
      if (token) {
        checkValidation(token, refreshToken, clientId, clientSecret)
          .then(() => {
            setToken('');

            // navigate('/', {
            //   state: {
            //     from: location.pathname
            //   },
            //   replace: true
            // });
            window.location.href = window.location.origin;
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  }, [queryParameters]);
  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === 'dark' ? '#000' : '#fff'
          }}
        />
      ) : (
        <>
          {!token ? (
            <>
              <Outlet />
              {showSettingPanelButton && (
                <>
                  <SettingsToggle />
                  <SettingsPanel />
                </>
              )}
            </>
          ) : (
            'Redirecting'
          )}
        </>
      )}
    </>
  );
};

export default App;
