import { useDispatch, useSelector } from 'store';
import AuthService from '../services/auth/AuthService';
import { setLogin, setLogout } from '../store/reducers/auth/authSlice';
import {
  AuthTenantType,
  AuthUserType,
  ChangePasswordType,
  ForgotPassword,
  Login,
  ResetPassword
} from '../types/auth';

const useAuthHook = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, user, tenant } = useSelector(state => state.auth);

  const login = async (data: Login) => {
    const resp = await AuthService.login(data);

    const token: string = resp?.data?.access_token || '';
    const refreshToken: string = resp?.data?.refresh_token || '';
    const user: AuthUserType = resp?.data?.data?.user || null;
    const tenant: AuthTenantType = resp?.data?.data?.tenant || null;
    const scopes = resp?.data?.scopes || [];
    const clientSecret: string = resp?.data?.client_secret || '';
    const clientId: string = resp?.data?.client_id || '';
    const appEnv = process.env.REACT_APP_APP_ENV || 'local';
    if (appEnv.toLowerCase() != 'local') {
      if (tenant?.redirect) {
        // const redirect = 'http://localhost:3001';
        const redirect = tenant?.redirect;
        window.location.href =
          redirect +
          '?login_token=' +
          token +
          '&refresh_token=' +
          refreshToken +
          '&client_id=' +
          clientId +
          '&client_secret=' +
          clientSecret;
      } else {
        console.log('Not found redirect url');
      }
    } else {
      dispatch(
        setLogin({
          user,
          tenant,
          token,
          refreshToken,
          clientId,
          clientSecret,
          scopes
        })
      );
      window.location.reload();
    }
  };
  const logout = async () => {
    AuthService.logout().catch(e => console.log('Logout Error:', e));
    dispatch(setLogout());
    // dispatch({ type: 'RESET_STATE' });
  };
  const forgotPassword = async (data: ForgotPassword) => {
    try {
      await AuthService.forgotPassword(data);
    } catch (e: unknown) {
      console.log('Forgot Password:', e);
      throw e;
    }
  };
  const resetPassword = async (data: ResetPassword) => {
    try {
      await AuthService.resetPassword(data);
    } catch (e: unknown) {
      console.log('Reset Password:', e);
      throw e;
    }
  };

  const changePassword = async (data: ChangePasswordType) => {
    try {
      await AuthService.changePassword(data);
    } catch (e: unknown) {
      console.log('Change Password:', e);
      throw e;
    }
  };

  const checkValidation = async (
    token: string,
    refreshToken: string,
    clientSecret: string,
    clientId: string
  ) => {
    try {
      const resp = await AuthService.tokenValidation(token);
      const user: AuthUserType = resp?.data?.data?.user || null;
      const tenant: AuthTenantType | null = user?.tenant || null;
      const scopes = resp?.data?.data?.scopes || [];
      const tokenWithScopes = token || '';
      const refreshTokenWithScopes = refreshToken || '';

      dispatch(
        setLogin({
          user,
          tenant,
          token: tokenWithScopes,
          refreshToken: refreshTokenWithScopes,
          clientId,
          clientSecret,
          scopes
        })
      );
    } catch (e: unknown) {
      console.log('checkValidation:', e);
      throw e;
    }
  };

  // const mappedLogin = (
  //   token: string,
  //   refreshToken: string,
  //   // eslint-disable-next-line
  //   resp: any
  // ) => {
  //   const user: AuthUserType = resp?.data?.client_id || null;
  //   const tenant: AuthTenantType | null = user?.tenant || null;
  //   const scopes = resp?.data?.scopes || [];
  //   const clientToken: string = resp?.data?.client_token || '';
  //   const clientId: string = resp?.data?.client_token || '';
  //
  //   return {
  //     user,
  //     tenant,
  //     token,
  //     refreshToken,
  //     clientId,
  //     clientToken,
  //     scopes
  //   };
  // };
  return {
    isAuthenticated,
    user,
    tenant,
    login,
    logout,
    forgotPassword,
    resetPassword,
    checkValidation,
    changePassword
  };
};

export default useAuthHook;
