import { createSlice } from '@reduxjs/toolkit';
import { PatientType } from '../../../types/patientManagement';

interface PatientState {
  patients: PatientType[];
}

const initialState: PatientState = {
  patients: []
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setAllPatient(state, action) {
      state.patients = action.payload.results || [];
    },
    addOnePatient(state, action) {
      state.patients = [...state.patients, action.payload.row];
    },
    setOnePatient(state, action) {
      state.patients = state.patients.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOnePatient(state, action) {
      state.patients = state.patients.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const { setAllPatient, addOnePatient, setOnePatient, removeOnePatient } =
  patientSlice.actions;
export default patientSlice.reducer;
