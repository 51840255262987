import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faKey
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// third-party
import * as Yup from 'yup';
import { Formik } from 'formik';
import useAuthHook from '../../../hooks/useAuthHook';

import React, { useState } from 'react';
import AlertMessage from '../../common/AlertMessage';
import { Login } from '../../../types/auth';
import { AlertType } from '../../../types/alert';
import { Trans, useTranslation } from 'react-i18next';

const SignInForm = () => {
  // eslint-disable-next-line
  const { t }: { t: any } = useTranslation();
  const { login } = useAuthHook();

  const [showPassword, setShowPassword] = useState(false);
  const [alert, setAlert] = useState<AlertType | null>(null);
  const [loader, setLoader] = useState<{ form?: boolean }>({
    form: false
  });

  const savedEmail = localStorage.getItem('rememberedEmail') || '';

  const [rememberMe, setRememberMe] = useState(!!savedEmail);

  const initialValues: Login = {
    email: savedEmail,
    password: ''
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .required(
        t('component.form.validation.mandatory', {
          field: t('common.label.email').toLowerCase()
        })
      )
      .email(t('component.form.validation.invalid_email')),

    password: Yup.string()
      .required(
        t('component.form.validation.mandatory', {
          field: t('common.label.password').toLowerCase()
        })
      )
      .min(
        6,
        t('component.form.validation.min', {
          field: t('common.label.password').toLowerCase(),
          min: '6'
        })
      )
  });

  const onSubmit = async (values: Login) => {
    setLoader({ form: true });

    if (rememberMe) {
      localStorage.setItem('rememberedEmail', values.email);
    } else {
      localStorage.removeItem('rememberedEmail');
    }

    login(values)
      .then(() => {
        setLoader({ form: false });
      })
      .catch(e => {
        if (e?.response?.status == '401') {
          setAlert({
            type: 'ERROR',
            message: t('common.message.unauthenticated')
          });
        } else if (e?.response?.status == '404') {
          setAlert({
            type: 'ERROR',
            message: t('common.message.unauthenticated')
          });
        } else {
          setAlert({ type: 'ERROR', message: t('common.message.failed') });
        }
        setLoader({ form: false });
      });
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-body-highlight">
          <Trans t={t}>auth.signIn.title</Trans>
        </h3>
        <p className="text-body-tertiary">
          <Trans t={t}>auth.signIn.sub_title</Trans>
        </p>
      </div>
      {alert && <AlertMessage type={alert.type} message={alert.message} />}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          isSubmitting,
          values,
          handleBlur,
          handleChange,
          errors,
          touched,
          handleSubmit
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="email">
                {' '}
                <Trans t={t}>common.label.email</Trans>
              </Form.Label>
              <div className="form-icon-container">
                <Form.Control
                  id="email"
                  type="email"
                  name="email"
                  className={`form-control form-icon-input ${
                    touched.email && errors.email ? 'is-invalid' : ''
                  }`}
                  placeholder={t('common.label.email')}
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                {touched.email && errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )}

                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="text-body fs-9 form-icon"
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3 ">
              <Form.Label htmlFor="password">
                {' '}
                <Trans t={t}>common.label.password</Trans>
              </Form.Label>

              <div className="form-icon-container">
                <Form.Control
                  autoComplete="on"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  className={`form-control form-icon-input ${
                    touched.password && errors.password ? 'is-invalid' : ''
                  }`}
                  placeholder={t('common.label.password')}
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {touched.password && errors.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
                <FontAwesomeIcon
                  icon={faKey}
                  className="text-body fs-9 form-icon"
                />
                <FontAwesomeIcon
                  onClick={handleClickShowPassword}
                  style={{ left: '90%', cursor: 'pointer' }}
                  icon={showPassword ? faEye : faEyeSlash}
                  className="text-body fs-9 form-icon"
                />
              </div>
            </Form.Group>
            <Row className="justify-content-between mb-4">
              <Col xs="auto">
                <Form.Check type="checkbox" className="mb-0">
                  <Form.Check.Input
                    type="checkbox"
                    name="remember-me"
                    id="remember-me"
                    checked={rememberMe}
                    onChange={handleRememberMeChange}
                  />
                  <Form.Check.Label htmlFor="remember-me" className="mb-0">
                    <Trans t={t}>common.label.remember_me</Trans>
                  </Form.Check.Label>
                </Form.Check>
              </Col>
              <Col xs="auto">
                <Link to={`/auth/forgot-password`} className="fs-9 fw-semibold">
                  <Trans t={t}>common.action.forgot_password</Trans>
                </Link>
              </Col>
            </Row>
            <Button
              type="submit"
              variant="primary"
              className="w-100 mb-3"
              disabled={isSubmitting || loader.form}
            >
              <Trans t={t}>common.action.sign_in</Trans>
            </Button>
            <Row>
              <Col className="fs-9">
                <Trans i18nKey="auth.common.agreement">
                  By Signing in, you are agreeing to our{' '}
                  <Link to="/terms-and-conditions">
                    {t('common.label.tnc')}
                  </Link>{' '}
                  and <Link to="/privacy-policy">Privacy policy</Link>.
                </Trans>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignInForm;
