import { createSlice } from '@reduxjs/toolkit';
import { HealthCenterType } from 'types/healthcenterManagement';

interface HealthCenterState {
  healthCenter: HealthCenterType[];
  healthCenterDetail: HealthCenterType;
}

const initialState: HealthCenterState = {
  healthCenter: [],
  healthCenterDetail: {} as HealthCenterType
};

const healthCenterSlice = createSlice({
  name: 'healthCenterSlice',
  initialState,
  reducers: {
    setAllHealthCenter(state, action) {
      state.healthCenter = action.payload.results || [];
    },
    setOneHealthCenter(state, action) {
      state.healthCenterDetail = action.payload.row;
    },
    addOneHealthCenter(state, action) {
      state.healthCenter = [...state.healthCenter, action.payload.row];
    },
    updateOneHealthCenter(state, action) {
      state.healthCenter = state.healthCenter.map(item => {
        if (item.id !== action.payload.id) return item;
        else return { ...item, ...action.payload.row };
      });
    },
    removeOneHealthCenter(state, action) {
      state.healthCenter = state.healthCenter.filter(
        item => item.id !== action.payload.id
      );
    }
  }
});

export const {
  setAllHealthCenter,
  setOneHealthCenter,
  addOneHealthCenter,
  updateOneHealthCenter,
  removeOneHealthCenter
} = healthCenterSlice.actions;
export default healthCenterSlice.reducer;
