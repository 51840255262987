import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import userReducer from './user/userSlice';
import roleReducer from './user/roleSlice';
import activityTypeReducer from './actor-mangement/acttivityTypeSlice';
import actorCategoryReducer from './actor-mangement/actorCategorySlice';
import actorReducer from './actor-mangement/actorSlice';
import locationReducer from './mdm/locationSlice';
import locationLevelReducer from './mdm/locationLevelSlice';
import venueReducer from './actor-mangement/venueSlice';
import venueTypeReducer from './actor-mangement/venueTypeSlice';
import patientReducer from './patient-management/patientSlice';
import formOptionReducer from './settings/formOptionSlice';
import encounterReducer from './encounter-management/encounterSlice';
import treatmentTypeReducer from './encounter-management/treatmentTypeSlice';
import fieldCustomizationReducer from './settings/fieldCustomizationSlice';
import encounterSettingsReducer from './settings/encounterSettingsSlice';
import oheReducer from './ohe/oheSlice';
import healthCenterReducer from './health-center-management/healthCenterSlice';
import csiReducer from './csi/csiSlice';
import bookingReducer from './booking-management/bookingSlice';
import generalSettingsReducer from './settings/generalSettingsSlice';
import dashboardReducer from './dashboard/dashboardSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  role: roleReducer,
  activityType: activityTypeReducer,
  actorCategory: actorCategoryReducer,
  actor: actorReducer,
  location: locationReducer,
  locationLevel: locationLevelReducer,
  venueType: venueTypeReducer,
  venue: venueReducer,
  patient: patientReducer,
  formOption: formOptionReducer,
  encounter: encounterReducer,
  treatmentType: treatmentTypeReducer,
  fieldCustomization: fieldCustomizationReducer,
  ohe: oheReducer,
  healthCenter: healthCenterReducer,
  csi: csiReducer,
  encounterSettings: encounterSettingsReducer,
  booking: bookingReducer,
  generalSettings: generalSettingsReducer,
  dashboard: dashboardReducer
});

export default rootReducer;
